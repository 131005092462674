<template>
  <router-link :to="localizedRoute('/compare')" aria-label="Compare icon" class="compare-icon no-underline inline-flex">
    <i class="icon-compare icon-size cl-alternative" />
    <span
      class="compare-count absolute flex center-xs middle-xs border-box py0 px2 h6 lh16 weight-700 cl-white bg-cl-silver"
      v-cloak
      v-show="getCompareProductsCount"
    >
      {{ getCompareProductsCount }}
    </span>
  </router-link>
</template>

<script>
import CompareIcon from '@vue-storefront/core/compatibility/components/blocks/Header/CompareIcon'
import { mapGetters } from 'vuex'

export default {
  mixins: [CompareIcon],
  computed: {
    ...mapGetters('compare', ['getCompareProductsCount'])
  }
}
</script>

<style scoped>
.icon-size {
  font-size: 37px;
}
.compare-count {
  top: -3px;
  left: 70%;
  background: #ff8100;
  min-width: 16px;
  min-height: 16px;
  border-radius: 10px;
}
</style>
